$(document).ready(function () {
    /*** Header fixed ***/
    $(window).scroll(function () {
        var scrollPixel = $(window).scrollTop();
        if (scrollPixel < 100) {
          $('header').removeClass('headerFix');
        } else {
          $('header').addClass('headerFix');
        };
    });

    headerResize();
    
});

/*** Mobile Header toggle ***/
function headerResize() {
    var screen_width = $(window).width();
    if (screen_width < 991) {
      $('.navbar-toggler').off('click');
      $('.navbar-toggler').on('click', function () {
        $('header nav').toggleClass('navbar-show');
        $('#divNavbar').slideToggle(200);
      });
  
      $("#divNavbar > ul > li.dropdown > .dropdown-menu").hide();
      $('#divNavbar > ul > li.dropdown > .arrow').off('click');
      $('#divNavbar > ul > li.dropdown > .arrow').on('click', function (event) {
        event.preventDefault();
        $(this).closest('#divNavbar > ul > li.dropdown').find('> .dropdown-menu').slideToggle('slow');
        $(this).parent().toggleClass('open-dropdown');
      });
    } else {
      $('header nav').removeClass('navbar-show');
      $('.navbar-toggler').attr('aria-expanded', 'false');
      $("#divNavbar > ul > li.dropdown > .dropdown-menu").removeAttr("style");
      $("header .navbar-collapse").removeAttr("style").removeClass('show');
    }
  }
  
  $(window).resize(function () {
    headerResize();
  });